<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Tools.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>please</b> do a Master Age And Score (Create, update, delete)
      </div>
      <div class="d-flex align-items-center">
        <b-dropdown
          size="sm"
          variant="link"
          toggle-class="custom-v-dropdown"
          no-caret
          right
          no-flip
          text="Actions"
          v-b-tooltip.hover="'Add Data'"
        >
          <template v-slot:button-content>
            <a class="btn btn-icon" data-toggle="dropdown">
              <span class="svg-icon svg-icon-success svg-icon-2x">
                <inline-svg src="media/svg/icons/Files/File-plus.svg" />
              </span>
            </a>
          </template>
          <!-- Navigation -->
          <div class="navi navi-hover min-w-md-650px">
            <b-dropdown-text tag="div" class="navi-header font-weight-bold">
              Add Data Master Age And Score
            </b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-separator mb-3"></b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-item">
              <a class="navi-link">
                <span class="navi-icon">
                  <i class="flaticon2-expand"></i>
                </span>
                <b-form-input size="sm" v-model="selected" list="options" placeholder="Select or type..." />
                <datalist id="options">
                  <option v-for="option in options" :value="option.text">{{ option.text }}</option>
                </datalist>

              </a>
            </b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-item">
              <a class="navi-link">
                <span class="navi-icon">
                  <i class="flaticon2-tag"></i>
                </span>
                <b-form-input size="sm" v-model="add_data.IQ" placeholder="Enter IQ" />
              </a>
            </b-dropdown-text>
            <b-dropdown-text tag="div" class="navi-footer">
              <a
                class="btn btn-light-primary font-weight-bolder btn-sm"
                @click="saveData"
              >
                Save
              </a>
            </b-dropdown-text>
          </div>
          <!-- End Navigation -->
        </b-dropdown>
      </div>
    </b-alert>

    <div class="card shadow-xs cardHover">
      <!-- Tabel -->
      <label></label>
      <label></label>
      <div class="form-group">
        <label for="name"></label>
        <b-form-group
          id="input-group-2"
          label-for="input-2"
          class="mb-2 mr-sm-2 mb-sm-0"
        >
          <b-form-input
            id="input-2"
            v-model="form.name"
            required
            placeholder="Search"
            class="mb-2 mr-sm-2 mb-sm-0"
          ></b-form-input>
        </b-form-group>
      </div>
      <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      >
        <b-table
          id="my-table"
          :filter="form.name"
          striped
          hover
          :items="items"
          :per-page="perPage"
          :fields="fields"
          :current-page="currentPage"
        ></b-table>
      </b-alert>

      <!-- Pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        size="lg"
        class="mt-3"
        align="center"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { Fetch_MComboAgeScore } from "@/core/services/store/mComboAgeScore.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Services from "@/core/services/psikologi-api/Services";
import ApiService from "@/core/services/api.service";
import Swal from 'sweetalert2'
import { ElSelect } from 'element-ui';

export default {
  data() {
    return {
      selected: null,
      options:[],
      criteria: "", // Properti filter
      fields: [],
      items: [],
      perPage: 10,
      currentPage: 1,
      form: {
        name: '',
      },
      add_data: {
        IQ: ''
      },
      loading: false,
      show: true
    };
  },
  components: {
        ElSelect,
    },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    filterTable(row, filter) {
      // Ganti row.age_from sesuai dengan kolom yang ingin di-filter
      if (row.age_from.includes(filter)) {
        return true;
      } else {
        return false;
      }
    },

    onSubmit(evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    onReset(evt) {
      evt.preventDefault()
      this.form.name = ''
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
  
    getMasterAgeIQ() {
      return new Promise((resolve, reject) => {
        let contentType = `application/form-data`;
        Services.GetData(
          ApiService,
          `master/iq/getscoreswithiq`,
          response => {
            resolve(response.data);
            this.items = response.data;
            this.fields = response.message;
          },
          err => {
            Swal.fire({
              title: "Data Not Found",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: true,
              timer: 1500
            });
            reject(err);
          }
        );
      });
    },

    saveData() {
      var selectedOption = this.options.find(option => option.text === this.selected);
      const formData = new FormData();
      formData.append("AgeScoreID", selectedOption.value);
      formData.append("IQ", this.add_data.IQ);

      let contentType = `application/form-data`;

      Services.PostData(
        ApiService,
        `master/iq/`,
        formData,
        contentType,
        // onSuccess function
        response => {
          if (response.status) {
            Swal.fire({
              title: "",
              text: "Data saved successfully.",
              icon: "success",
              heightAuto: true,
              timer: 1500
            });
            this.loadData();
          } else {
            Swal.fire({
              title: "",
              text: response.data.error,
              icon: "info",
              heightAuto: true,
              timer: 1500
            });
          }
        },
        // onError function
        error => {
          console.error('Error during API call:', error);
          // Handle unexpected errors
        }
      )
    },


    GetCombo() {
      this.$store.dispatch(Fetch_MComboAgeScore);
      var data_combo = this.$store.state.mAgeScore.master_ComboAgeScore;
      this.options = data_combo.map(item => ({
        value: item.AgeScoreID,
        text: item.AgeScore
      }));
    },

    loadData() {
      this.getMasterAgeIQ()
        .then(() => {})
        .catch(error => {
          console.error(error);
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/master" },
      { title: "Psychometric", route: "/metric" },
      { title: "Formula By score", route: "/score_score_iq" }
    ]);
    this.GetCombo();
    this.loadData();
  }
};
</script>

<style>
.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.form-group label {
  margin-right: 10px;
  flex-grow: 1; /* Optional: Add this to make sure the label takes up the available space on the left */
}
.form-group input {
  flex-grow: 2; /* Optional: Add this to make sure the input field takes up more space on the right */
}
</style>
